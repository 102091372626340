.custom-btn {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px 0 16px;
    border-radius: 4px;
    overflow: visible;
    outline: none !important;
    // display: flex;
    display: inline-block;
    // align-items: center;
    font-size: 14px;
    // margin-left: 20px;
    .mat-icon {
        margin-right: 8px !important;
    }
}

.general-btn {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    //border: none;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px 0 16px;
    border-radius: 20px;
    overflow: visible;
    outline: none !important;
    // display: flex;
    display: inline-block;
    // align-items: center;
    font-size: 14px;
    // margin-left: 20px;
    .mat-icon {
        margin-right: 8px !important;
    }
}

.red-btn {
    color: #fff !important;
    background-color: #f23800 !important;
    border-color: #f23800 !important;
    &:hover {
        background-color: rgb(223, 57, 7) !important;
    }
}

.green-btn {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;

    mat-icon{
        color: #f23800;
        margin-top: -15px;
    }
    &:hover {
        background-color: #218838 !important;
        border-color: #1e7e34 !important;
    }
    &:disabled {
        background-color: rgba(40, 167, 69, 0.35) !important;
        cursor: default;
    }
}

.dark-green-btn {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;

    mat-icon{
        color: #f23800;
        margin-top: -15px;
    }
    &:hover {
        background-color: whitesmoke;
        color: #28a745;
        border: 1px;
        border-color: #28a745;
        box-shadow: 4px 8px 10px 2px rgba(135, 135, 135, 0.9);
        transition: all 0.5s ease 0s;
        .mat-icon {
            animation-name: bounce;
            -moz-animation-name: bounce;
        }
    }
    &.radius {
        border-radius: 20px;
    }
    &:disabled {
        background-color: rgba(40, 167, 69, 0.35) !important;
        cursor: default;
    }
}

.dark-blue-btn {
    background-color: #19577b;
    color: #fff;
    &:hover {
        background-color: #264c7d;
        color: white;
        border: 1px solid white;
        box-shadow: 2px 6px 8px 2px rgba(135, 135, 135, 0.9);
        transition: all 0.5s ease 0s;
        .mat-icon {
            animation-name: bounce;
            -moz-animation-name: bounce;
        }
    }
    &.radius {
        border-radius: 20px;
    }
}

.gradient-green-btn {
    background: linear-gradient(-20deg, #74DA67 0%, #26B09F 100%);
}

.blue-btn {
    background: var(--primary-color) !important;
    color: white !important;
    &:hover {
      background: var(--primary-color-light) !important;
    }
    &:disabled {
        // background-color: rgba(221, 221, 221, 0.35) !important;
        background-color: rgba(195, 195, 195, 0.35) !important;
        color: #999 !important;
        cursor: default;
    }
}

.generalbtn {
    width: 100%;
    //   background: #7FD0ED !important;
    background: #189fcf !important;
    color: white !important;
    border-radius: 20px !important;
    &:hover {
        background: #3AB6E4 !important;
    }
    &:disabled {
        // background-color: rgba(221, 221, 221, 0.35) !important;
        background-color: rgba(195, 195, 195, 0.35) !important;
        color: #999 !important;
        cursor: default;
    }
}


/* for adding */
.icon-btn {
    width: 47px;
    height: 44px;
    border: 1px solid #cdcdcd;
    background: #00456e;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    transition: width 0.2s ease-in-out;
    font-weight: 500;
    font-family: inherit;
    margin-top: -15px;
  }
  
  .add-btn:hover {
    width: max-content;
    color: white;
  }
  
  .add-btn::before,
  .add-btn::after {
    transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 4px;
    width: 10px;
    top: calc(50% - 2px);
    //background: seagreen;
    background: white;
  }
  
  .add-btn::after {
    right: 14px;
    overflow: hidden;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .add-btn::before {
    left: 14px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .icon-btn:focus {
    outline: none;
  }
  
  .btn-txt {
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .add-btn:hover::before,
  .add-btn:hover::after {
    width: 4px;
    border-radius: 2px;
  }
  
  .add-btn:hover .btn-txt {
    opacity: 1;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .add-icon::after,
  .add-icon::before {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 20px;
    width: 2px;
    top: calc(50% - 10px);
    background: white;
    //background: seagreen;
    overflow: hidden;
  }
  
  .add-icon::before {
    left: 22px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .add-icon::after {
    right: 22px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .add-btn:hover .add-icon::before {
    left: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }
  
  .add-btn:hover .add-icon::after {
    right: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }
  
  .help-button{
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 24px 0 16px;
    border-radius: 4px;
    overflow: visible;
    outline: none !important;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin-left: 20px;
    .mat-icon{
        margin-right: 8px !important;
    }

}

.bn-color {
  background: white !important;
  color: #214F7E !important;
}


.bn-color:hover {
  background-color: #214F7E !important;
  color: aliceblue !important;
}

.mat-menu-item .mat-icon {
  /* margin-right: 16px; */
  vertical-align: middle !important;
  margin: auto 5px 0px -3px !important;
}

.mat-menu-item:not(:last-of-type){
  border-bottom: .2px solid #80808045 !important;
}

 // incident actions btns
 .incident-actions{
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 5px 10px 10px;
   .action-btn {
    display: inline-block;
    position: relative;
    padding: 5px 14px;
    color: white;
    font-family: sans-serif;
    text-decoration: none;
    font-size: 0.9em;
    text-align: center;
    border: none;
    margin-right: 3px;
    height: 28px;
    border-radius: 4px;
    }
    
    .action-btn:hover {
      background-color: #45a21a;
      color: white;
    }
    .edit{
     background-color: #2e8009;;
    }
    .recategorize{
     background-color: #475143;;
    }
    .recategorized{
     background-color: #2e8009;;
    }
    .resume{
     background-color: #2e8009;;
    }
    .merge{
     background-color: rgb(101 131 120);
    }
    .forward{
     background-color: #3c529e;;
    }
    .delete{
      background-color: red;
    }
    .submit{
      background-color: rgb(4 83 54);
    }
    
    
  }
.action-button {
  color: var(--primary-color) !important;
  background: var(--light-color) !important;

  &:hover{
    background: var(--primary-color) !important;
    color: var(--light-color) !important;
  }
}

// .action-button::hover {
//   color: var(--primary-color) !important;
//   background: var(--light-color) !important;
// }