/* You can add global styles to this file, and also import other style files */
@import "../node_modules/normalize.css/normalize.css";
// @import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/scss/buttons";
@import "./assets/scss/custom";
@import "~@angular/material/theming";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";


:root{
  --position: 1;
  --primary-color: #30197b;
  --primary-color-light: #30197b82;
  --light-color: #fefefe;
  --danger-color: #c91b1b;

}

html, body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  //background-image: url("./assets/images/twiga.png");
  //background-size: cover;
  //background-position: 49% 113%;
  //background-repeat: no-repeat;
}



.green-snackbar {
  background: green;
  color: #fff;
}

.red-snackbar {
  background: red;
  color: #fff;
}

.warning-snackbar {
  background: #ea580c;
  background: #f97316;
  color: #fff;
}





// Some Animations

.fade-on {
  animation: fade-on calc(calc(var(--position) * 200ms) + 100ms) ease 0s forwards;
}

@keyframes fade-on {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.scale-up {
  animation: scale-up calc(calc(var(--position) * 200ms) + 100ms) ease 0s forwards;
}

@keyframes scale-up {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.expand-width {
  animation: scale-up calc(calc(var(--position) * 200ms) + 100ms) ease 0s forwards;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background: var(--primary-color) !important;
}

@keyframes expand-width {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
