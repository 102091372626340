
body{
    overflow-y: auto;
    color: #151b26;
    font-family: 'Roboto';
    .login-holder {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
}
body *{
    box-sizing: border-box;
}
body::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar {
    width: 10px;
}
body::-webkit-scrollbar-track, .mat-dialog-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
//   outline: 1px solid slategrey;
  border-radius: 5px;
}
a{
    text-decoration: none !important;
    outline : none;
}
button{
    outline : none !important;
}
.scrollable-element {
    scrollbar-width: thin;
}
.mat-form-field {
    width: 100%;
}
.mat-icon {
    height: 22px !important;
    width: 22px !important;
    overflow: inherit !important;
}

// OVERWRITE SWEETALERT2
.swal2-popup{
  width: 22em !important;
  padding: 1.1em !important;
  font-size: 14px !important;
}

//.swal2-icon{
//    width: 3rem !important;
//    height: 3rem !important;
//    margin: 0.5em auto 1em !important;
//}
.swal2-title{
  margin: 0 0 0em;
}
.swal2-actions{
  margin: auto !important;
}
.swal2-styled{
  font-size: 14px !important;
}
.mat-typography h2{
  font: 500 20px/20px Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px !important;
}
.swal2-icon .swal2-icon-content{
  font-size: 3em !important;
}

.inner-filter {
  display: flex;
  flex-flow: row wrap;
  justify-content: center !important;
  align-items: center !important;
  .line-label {
    margin-top: 8px;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }
  padding-bottom: 20px;
  border-bottom: 1px dashed #eaeaea;
}

.red-snackbar{
  background: #F44336 !important;
  color: #fff;
  font-weight: 500;
  font-size: 26px !important;

  button {
    background-color: #C62828;
    color: white;
  }
}
.blue-snackbar{
  background: #3f4344 !important;
  color: #fff;
  font-weight: 500;
  font-size: 26px !important;

  button {
    background-color: #037b99;
    color: white;
  }
}
.inner-links{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end !important;

  button, a{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
}
.mat-dialog-container{
  // border-radius: 0px !important;
  // border: 1px solid #0f83a5;
  // border-bottom: 4px solid #ffa65d;
  border-radius: 10px !important;
}

.save-right{
  display: flex;
  justify-content: flex-end !important;
}

// DASHBOARD STYLES
.dashbox{
  color: #697079;
  border: 1px solid rgb(230, 236, 241);
  border-color: #bde0c1;
  margin: 0px;
  display: flex;
  padding: 0px;
  position: relative;
  place-self: stretch;
  box-shadow: rgba(116, 129, 141, 0.2) 0px 3px 8px 0px;
  align-items: center;
  border-radius: 3px;
  flex-direction: row;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  padding: 25px 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: border 250ms ease 0s;
  position: relative;
  background: #fdfdfd;
  outline: none !important;
  &:hover{
    // background: #fcfcfc45;
    border-color: #ffbe46;
    background: #fff4d5;
    transform: scale(1.02);
  }
  span.count{
    margin-left: auto;
    font-size: 2rem;
    color: #19577b;
  }
  .topicon{
    position: absolute;
    top: -15px;
    left: 15px;
    color: #de7e2e;
  }
}

.notification-header {
  display: flex;
  margin: 1px;
  justify-content: center;
  h4{
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1px;
  }

  .header{
      border: 1px dotted !important;
      padding: 2px;
  }
  .notication-box{
    color: #697079;
    border: 1px solid #e6ecf1;
    border-color: #bde0c1;
    margin: 2px !important;
    display: flex;
    // padding: 0px !important;
    position: relative;
    place-self: stretch;
    box-shadow: rgb(116 129 141 / 20%) 0px 3px 8px 0px;
    align-items: center;
    border-radius: 3px;
    flex-direction: row;
    text-decoration: none;
    background-color: white;
    padding: 2px 2px;
    cursor: pointer;
    font-size: 11px !important;
    position: relative;
    background: #fdfdfd;
    outline: none !important;
    &:hover{
      // background: #fcfcfc45;
      border-color: #ffbe46;
      background: #fff4d5;
      transform: scale(1.02);
    }
    span.count{
      margin-left: auto;
      font-size: 14px;
      color: #19577b;
    }
    .topicon{
      position: absolute;
      top: -15px;
      left: 15px;
      color: #de7e2e;
    }
  }
}

.actions-center{
  justify-content: center;
}
.mat-tab-body-content{
  overflow: hidden !important;
}
.mat-ink-bar{
  height: 4px !important;
}
.mat-tab-group.mat-primary .mat-ink-bar{
  // background: #19577b;
  background: #41718b;
}
.mat-dialog-container{
  overflow: visible !important;
}

// Override Dialog Container Style
.incident-details{
  .mat-dialog-container {
    padding: 15px !important;
    background: #f3f3f3;
    border: 0px;
  }
}

.mat-menu-panel.sub-links{
  // min-width: 200px !important;
}

.mat-accordion>:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing{
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.faded-slight {
  opacity: 0.8;
}

// MULT-COLUMN CSS
.subheader {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
  height: auto;
  padding-top: 8px;
  margin-left: 3%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 3%;
  font-weight: 500;
}

.list {
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  margin-left: 1%;
  margin-top: 16px;
  margin-bottom: 10px;
  padding-top: 2px;
  padding-left: 1px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 300ms ease-in-out;
}
.list:hover {
  font-weight: bold;
  background-color: rgb(248, 243, 243);
  cursor: pointer;
  transform: scale(1.01);
}
.selected {
  font-weight: bold;
  background-color: rgb(230, 224, 224);
}

.no-defined-service {
  color: red;
  display: block;
  padding: 28px;
  text-align: center;
}

.search-services {
  margin-top: 10px;
  margin-bottom: 18px;
  border-bottom: 1px dashed #ddd;
  // padding-bottom: 15px;
  ::ng-deep {
    .mat-form-field-label-wrapper {
      top: -1.3em;
    }

    .mat-form-field-flex {
      width: 90%;
      margin-left: 5% !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.5em 0;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: #0089d8;
    }

    mat-form-field.mat-form-field {
      color: #0089d8;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: #0089d8;
    }

    .mat-form-field-appearance-outline .mat-form-field-prefix {
      margin-right: 10px !important;
    }
  }
}

// Incident Status Menu 
  .draft {  
    // background-color: burlywood !important;
    color: rgb(244, 185, 6);
  }
  .open {  
    // background-color: #3a13ac ;
    color: #3a13ac;
  }
  .assigned {  
    // background-color: #07472a  ;
    color: #07472a;
  }
  .resolved {  
    // background-color: #413405 ;
    color: brown;
  }
  .closed {  
    // background-color: #11aa11 ;
    color: #11aa11;
  }
  .frozen {  
    // background-color: #11aa11 ;
    color: #14262f;
  }
  
  .back-button{
    margin-left: auto;
    margin-top: -10px;
    span{
        border: 1px solid #ddd;
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: #1a926d;
        &:hover{
            border-color: #3dd4f7;
            background: #fdfdfd;
        }
    }
}

.no-print{
  display: flex;
  text-align: center;
  margin-bottom: -25px;

  .nav-btn{
    padding: 0px 12px !important;
    background-color: #a8a8a8  !important;
    color: white;
    text-transform: uppercase;
  }
  
  .active-button {
    color: #fff;
    background-color: #375479  !important;
  }
  section {
    margin-bottom: 20px;
    button {
      margin: 5px 5px;
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
      font-size: 10px;
      line-height: 28px;
    }
    button:hover {
      transform: scale(1.03);
      font-weight: 500;
      // color: black;
    }
    
  }
  
  .count {
    font-weight: 500;
    font-size: 13px;
    border-left: 1px solid #d9d9d9;
  }
}

// table css
.datagrid table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  .action {
    width: 10%;
    .delete, .read{
      cursor: pointer;
      color: red;
    }
    .edit{
      cursor: pointer;
      color: green;
    }
    .download{
      cursor: pointer;
      color: black;
    }
  }
  .no {
    width: 11%;
  }
}
.datagrid {
  font: normal 12px/150% Arial, Helvetica, sans-serif;
  background: #fff;
  overflow: hidden;
  border: 1px solid #999190;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
}
.datagrid table td, .datagrid table th {
  padding: 2px 9px;
}
.datagrid table thead th {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #999697),
    color-stop(1, #767f67)
  );
  background: -moz-linear-gradient(center top, #999697 5%, #767f67 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#999697', endColorstr='#767F67');
  background-color: #999697;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  border-left: 1px solid #a8a78d;
}
.datagrid table thead th:first-child {
  border: none;
}
.datagrid table tbody td {
  color: #00496b;
  font-size: 13px;
  border-bottom: 1px solid #e1eef4;
  font-weight: normal;
}
.datagrid table tbody .alt td {
  background: #e1eef4;
  color: #616b65;
}
.datagrid table tbody td:first-child {
  border-left: none;
}
.datagrid table tbody tr:last-child td {
  border-bottom: none;
}

.last-col-narrow {
  .mat-row .mat-cell:last-child {
    flex: 0 0 100px;
  }

  .mat-header-row .mat-header-cell:last-child {
    flex: 0 0 100px;
    justify-content: center;
  }

  .action-icons {
    justify-content: center;
  }
}


.last-col-wide {
  .mat-row .mat-cell:last-child {
    flex: 0 0 220px;
  }

  .mat-header-row .mat-header-cell:last-child {
    flex: 0 0 220px;
    justify-content: space-evenly;
  }

  .action-icons {
    justify-content: space-evenly;
  }
}


.second-col-very-narrow {
  .mat-row .mat-cell:nth-child(2) {
    flex: 0 0 80px !important;
  }

  .mat-header-row .mat-header-cell:nth-child(2) {
    flex: 0 0 80px !important;
  }
}

.second-col-medium {
  .mat-row .mat-cell:nth-child(2) {
    flex: 0 0 200px;
  }

  .mat-header-row .mat-header-cell:nth-child(2) {
    flex: 0 0 200px;
  }
}

.second-col-narrow {
  .mat-row .mat-cell:nth-child(2) {
    flex: 0 0 150px;
  }

  .mat-header-row .mat-header-cell:nth-child(2) {
    flex: 0 0 150px;
  }
}

.third-col-very-narrow {
  .mat-row .mat-cell:nth-child(3) {
    flex: 0 0 80px !important;
  }

  .mat-header-row .mat-header-cell:nth-child(3) {
    flex: 0 0 80px !important;
  }
}

.third-col-narrow {
  .mat-row .mat-cell:nth-child(3) {
    flex: 0 0 150px;
  }

  .mat-header-row .mat-header-cell:nth-child(3) {
    flex: 0 0 150px;
  }
}

.third-col-medium {
  .mat-row .mat-cell:nth-child(3) {
    flex: 0 0 200px;
  }

  .mat-header-row .mat-header-cell:nth-child(3) {
    flex: 0 0 200px;
  }
}

.fourth-col-narrow {
  .mat-row .mat-cell:nth-child(4) {
    flex: 0 0 150px;
  }

  .mat-header-row .mat-header-cell:nth-child(4) {
    flex: 0 0 150px;
  }
}


.fith-col-narrow {
  .mat-row .mat-cell:nth-child(5) {
    flex: 0 0 150px;
  }

  .mat-header-row .mat-header-cell:nth-child(5) {
    flex: 0 0 150px;
  }
}

.sixth-col-narrow {
  .mat-row .mat-cell:nth-child(6) {
    flex: 0 0 150px;
  }

  .mat-header-row .mat-header-cell:nth-child(6) {
    flex: 0 0 150px;
  }
}


.seventh-col-narrow {
  .mat-row .mat-cell:nth-child(7) {
    flex: 0 0 150px;
  }

  .mat-header-row .mat-header-cell:nth-child(7) {
    flex: 0 0 150px;
  }
}

.no-comments {
  display: flex;
  justify-content: center;
  margin-top: 41px;
  color: red;
}

.text-accent{
  color: #61d5de !important;
}

.action-menu {
  display: flex;
  justify-content: center;
  margin: 0;
  // margin-top: 10px;
  // margin-bottom: 10px;
}

.mat-menu-content:not(:empty){
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: fit-content !important;
  border-radius: .5em !important;
  // color: var(--primary-color) !important;
}

.mat-menu-item {
  color: var(--primary-color) !important;

  .mat-icon {
    color: inherit !important;;
  }
}

.mat-menu-item:last-child.delete{
  color: var(--danger-color) !important;
}

.mat-menu-item > .btn-danger {
  color: var(--danger-color) !important;
}

.mat-menu-content:not(:empty):last-child() {
  color: var(--danger-color) !important;
}
// .mat-menu-content:not(:empty) {
//   padding-top: 0;
//   padding-bottom: 0;
// }